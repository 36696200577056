html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter';
  background-color: var(--background);
  color: var(--text-color);
  height: 100%;
  overflow-y: scroll;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.web3modal-modal-lightbox {
  z-index: var(--layer-10) !important;
}

html::-webkit-scrollbar-track {
  background-color: #e1e1e1;
}

html::-webkit-scrollbar {
  width: var(--u);
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: #8f8c9c;
  border-radius: 100px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
